<template>
    <BaseLayout>
      <div class="ol-addcart" id="copy_voucher_url" hidden="true">
        <i class=""></i> Kode Voucher Berhasil tersimpan
      </div>
      <div class="break-promotion-top"></div>
      <!-- <div class="ol-wrapper ol-wrapper--large">
          <div class="title-div-content" style="text-align:center">
              <h2 class="heading-content font-moreversary">MOREVERSARY 3th</h2>
              <span class="sub-heading-content">#3uforiaBelanja</span>
          </div>
          <BannerPromotion :banners="bannerHead" :clickable="true"  />
      </div> -->

      <div class="ol-wrapper ol-wrapper--large" id="retail">
          <div class="title-div-content" style="text-align:center">
              <h2 class="heading-content" style="color:#364B7B">MORE RETAIL</h2>
              <span class="sub-heading-content">Gebyar Ulang Tahun di MORE Furniture Retail Experience!</span>
          </div>
          <div class="title-moreversary heading-content">Kalender Promo Retail</div>
          <ul class='timeline'>
            <li class='work'>
              <input class='radio' id='work0' name='works' type='radio' >
              <div class="relative">
                <label class="label-timeline" for='work0'>Tebus Murah & Lucky Dip
                  </label>
                <span class='date'>1-31 Juli 2024</span>
                <span class='circle'></span>
              </div>
              <div class='content'>
                <p >
                  Dapatkan kesempatan undian potongan produk BEST SELLER MORE setiap pembelian produk-produk tebus murah pilihan. Tunggu apalagi? Yuk checkout produk favoritmu sekarang
                </p>
              </div>
            </li>
            <li class='work'>
              <input class='radio' id='work5' name='works' type='radio'>
              <div class="relative">
                <label class="label-timeline" for='work5'>Night Shopping Discount Up To 80%
                  </label>
                <span class='date'>6-7 Juli 2024</span>
                <span class='circle'></span>
              </div>
              <div class='content'>
                <p class="extra-content">
                  Dapatkan Discount up to 80% khusus jam 19.00-21.00 WIB; Pembelian terbatas 1 item per orang dan tidak berlaku kelipatan. Serbu diskon Night Sale MORE sebelum kehabisan!
                </p>
              </div>
            </li>
            <li class='work'>
              <input class='radio' id='work4' name='works' type='radio' >
              <div class="relative">
                <label class="label-timeline" for='work4'>Weekend Deals 33% + 33% & 33 Produk</label>
                <span class='date'>13-14 Juli 2024</span>
                <span class='circle'></span>
              </div>
              <div class='content'>
                <p class="extra-content">
                  Dapatkan diskon 33%+33% untuk 33 produk terbaik pilihan MORE. Pembelian terbatas 1 item dan tidak berlaku kelipatan
                </p>
              </div>
            </li>
            <li class='work'>
              <input class='radio' id='work3' name='works' type='radio' checked>
              <div class="relative">
                <label class="label-timeline" for='work3'>More Birthday Gift</label>
                <span class='date'>17-21 Juli 2024</span>
                <span class='circle'></span>
              </div>
              <div class='content'>
                <p>
                  Khusus untuk kamu yang berulang tahun di bulan juli, dapatkan diskon menarik saat berbelanja di MORE Furniture Retail Experience. Segera klaim hadiahmu sekarang!
                </p>
              </div>
            </li>
            <li class='work'>
              <input class='radio' id='work2' name='works' type='radio'>
              <div class="relative">
                <label class="label-timeline" for='work2'>MEGA FLASHSALE Moreversary</label>
                <span class='date'>27-28 Juli 2024</span>
                <span class='circle'></span>
              </div>
              <div class='content'>
                <p class="extra-content">
                  Gebyar promo dan diskon puncak ulang tahun MOR3VERSARY. Dapatkan penawaran-penawaran eksklusif hanya untukmu di MORE Furniture Retail Experience
                </p>
              </div>
            </li>
          </ul>
          <div class="title-moreversary heading-content" style="display:none;">GEBYAR EVENT</div>
          <div class="break-16" style="display:none;"></div>
          <div class="wrapper-event" style="display:none;">
            <div>
              <div class="row no-gutters" >
                <div class="col-6">
                    <img :src="require('@/assets/images/MOREVERSARY/Coming-Soon.jpg')"  width="100%" height="100%" style="border-radius: 8px;"/>
                </div>
                <div class="col-6">
                    <div class="content-location">
                        <div class="content-location-detail" style="text-align:left">
                            <h4 style="font-weight: 800;line-height: 34px;color: #141414;">Coming Soon</h4>
                            <!-- <div class="content-location-info" >
                                <table>
                                <tr>
                                    <td style="padding:4px;"><img :src="require('@/assets/images/interior/location.png')"  width="16" height="16" style="vertical-align: top;"/></td>
                                    <td style="padding:4px;">-</td>
                                </tr>
                                <tr>
                                    <td style="padding:4px;"><img :src="require('@/assets/images/interior/time.png')"  width="16" height="16" /></td>
                                    <td style="padding:4px;">-</td>
                                </tr>
                                </table>
                            </div>
                            <div style="margin-top:16px;margin-bottom:16px;">
                                <a class="content-location-button-1" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Daftar Sekarang</a>
                            </div> -->
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row no-gutters" >
                <div class="col-6">
                    <img :src="require('@/assets/images/MOREVERSARY/Coming-Soon.jpg')"  width="100%" height="100%" style="border-radius: 8px;"/>
                </div>
                <div class="col-6">
                    <div class="content-location">
                        <div class="content-location-detail" style="text-align:left">
                            <h4 style="font-weight: 800;line-height: 34px;color: #141414;">Coming Soon</h4>
                            <!-- <div class="content-location-info" >
                                <table>
                                <tr>
                                    <td style="padding:4px;"><img :src="require('@/assets/images/interior/location.png')"  width="16" height="16" style="vertical-align: top;"/></td>
                                    <td style="padding:4px;">-</td>
                                </tr>
                                <tr>
                                    <td style="padding:4px;"><img :src="require('@/assets/images/interior/time.png')"  width="16" height="16" /></td>
                                    <td style="padding:4px;">-</td>
                                </tr>
                                </table>
                            </div>
                            <div style="margin-top:16px;margin-bottom:16px;">
                                <a class="content-location-button-1" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Daftar Sekarang</a>
                            </div> -->
                        </div>
                        
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="break-16"></div>
      </div>


      <div class="ol-wrapper ol-wrapper--large margin-top wrapper-event" >    
            <div class="row no-gutters" style="background: #F6F7F9;" id="interior">
                <div class="col-12">
                    <img :src="require('@/assets/images/interior/Facade-More-521x316.jpg')"  width="100%" height="100%"  style="border-radius: 8px;"/>
                </div>
                <div class="col-12">
                    <div class="content-location">
                        <div class="content-location-detail" style="text-align: left;width:100%;padding:0px;">
                            <h2 class="heading-content" style="color:#364B7B">MORE FURNITURE RETAIL EXPERIENCE</h2>
                            <span class="sub-heading-content">Dapatkan promo istimewa MORE RETAIL</span>
                            <div class="break-16"></div>
                            <div class="wrapper-marketplace">
                            <div>
                                <a href="https://asset.morefurniture.id/MORE/retail/Brosur-Moreverserary.pdf" target="_blank" class="icon-button-2">
                                    Lihat Brosur
                                </a>
                            </div>
                            <div>
                                <a href="https://api.whatsapp.com/send?phone=628113066171" target="_blank" class="icon-button-1">
                                    Whatsapp
                                </a>
                            </div>
                            <div>
                                <a href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank" class="icon-button-2">
                                    Menuju Lokasi
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div> 
          <div class="row no-gutters" style="background: #F6F7F9;" id="interior">
            <div class="col-12">
                <img :src="require('@/assets/images/retail/Banner-Interior-Web.jpg')"  width="100%" height="100%"  style="border-radius: 8px;"/>
            </div>
            <div class="col-12">
                <div class="content-location">
                    <div class="content-location-detail" style="text-align: left;width:100%;padding:0px;">
                        <h2 class="heading-content" style="color:#364B7B">MORE Custom Interior</h2>
                        <span class="sub-heading-content">Dapatkan promo istimewa Custom Interior MORE</span>
                        <div class="break-16"></div>
                        <div class="wrapper-event">
                          <div>
                              <a href="http://bit.ly/more-custom" target="_blank" class="icon-button-2">
                                Konsultasi Sekarang
                              </a>
                          </div>
                          <div>
                              <a href="desain-interior" target="_blank" class="icon-button-1">
                                Lihat Portofolio
                              </a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div class="break-16"></div>
    </BaseLayout>
  </template>
  
  <script>
  import BaseLayout from '@/components/landing/BaseLayout';
  import BannerBottomLoading from '@/components/loading/BannerBottomLoading';
  import BannerTop from '@/components/home/BannerTop';
  import BannerPromotion from '@/components/home/BannerPromotion';
  import ProductLoader from '@/components/loading/product/Content';
  import CarouselProduct from '@/components/home/CarouselProduct';
  import CarouselProductFree from '@/components/home/CarouselProductFree';
  import CarouselProductCombo from '@/components/home/CarouselProductCombo';
  import FlashsaleGroup from '@/components/product/FlashsaleGroup';
  import ProductListGroup from '@/components/product/ProductListGroup';
  import { mapActions, mapGetters } from 'vuex';
  
  
  export default {
    name: 'MoreversaryRetail',
    metaInfo() {
      return {
        link: [
          {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
        ]
      }
    },
    components: {
      BaseLayout,
      BannerBottomLoading,
      BannerTop,
      BannerPromotion,
      ProductLoader,
      CarouselProduct,
      CarouselProductFree,
      FlashsaleGroup,
      ProductListGroup,
      CarouselProductCombo,
    },
    data() {
      return {
        loading:false,
        loadingBottom:false,
        loadingLastPaket:false,
        loadingFrees:false,
        loadingCombos:false,
        loadingPakets:false,
        loadingLastPaket:true,
        bannerHead:[
            {
              link_banner:"#",
              mobile:require('@/assets/images/MOREVERSARY/moreversary_1.jpg'),
              web:require('@/assets/images/MOREVERSARY/moreversary_1.jpg')
            },
            {
              link_banner:"#",
              mobile:require('@/assets/images/MOREVERSARY/moreversary_2.jpg'),
              web:require('@/assets/images/MOREVERSARY/moreversary_2.jpg')
            },
            {
              link_banner:"#",
              mobile:require('@/assets/images/MOREVERSARY/moreversary_3.jpg'),
              web:require('@/assets/images/MOREVERSARY/moreversary_3.jpg')
            }
        ]
      }
    },
    computed: {
      ...mapGetters('product/list', ['products', 'topProducts']),
      ...mapGetters('home', ['bannerTop', 'productGroups']),
      ...mapGetters('auth', ['authenticated']),
      ...mapGetters('promotion', ['frees', 'combos','pakets']),
      ...mapGetters("product/detail", ['voucher']),
      ...mapGetters('profile/product', ['lastSeen','lastPaket']),
      ...mapGetters("transaction/cart", ["reedem","checkReedem","checkMinReedem"]),
    },
    methods: {
      ...mapActions("transaction/cart", ["fetchReedem"]),
      ...mapActions('home', ['fetchProductGroups', 'getBanner']),
      ...mapActions('product/list', ['fetchProducts', 'fetchTopProducts']),
      ...mapActions('promotion', ['fetchFrees', 'fetchCombos', 'fetchPakets']),
      initSlickRecomendation() {
        if($('.ol-home-group-list').hasClass("slick-initialized")) $(".ol-home-group-list").slick('unslick');
        $(".ol-home-group-list").slick({
          slidesToScroll: 1,
          swipeToSlide: true,
          verticalSwiping: false,
          slidesToShow: 5,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5e3,
          arrows: !0,
          pauseOnFocus: !1,
          infinite: false,
          speed: 500,
          fade: !1,
          responsive: [{
            breakpoint: 1500,
            settings: {
              slidesToShow: 5
            }
          }, {
            breakpoint: 1160,
            settings: {
              slidesToShow: 4
            }
          }, {
            breakpoint: 748,
            settings: {
              slidesToShow: 3
            }
          }, {
            breakpoint: 530,
            settings: {
              slidesToShow: 3
            }
          }, {
            breakpoint: 481,
            settings: {
              slidesToShow: 2
            }
          }]
        });
      },
      scrollToHash(hash) {
        hash = hash.replace('#', '');
        const h = document.getElementsByTagName('header')[0].offsetHeight + document.getElementById('header-promotion').offsetHeight;
        const el = document.getElementById(hash);
        if(el) {
          const top = el.getBoundingClientRect().top + window.pageYOffset - h;
          window.scrollTo({top: top, behavior: 'smooth'});
          this.$router.push({hash: hash});
        }
      },
      copyNumber(value) {
        navigator.clipboard.writeText(value).then(function () {
          $('#copy_voucher_url').prop('hidden',false);
          setTimeout(() => { $('#copy_voucher_url').prop('hidden',true); }, 1500);
        }, function () {
          // alert('Failure to copy. Check permissions for clipboard')
        });
      },
      async getReedem(){
          await this.fetchReedem();
      },
    },
    async created() {
      this.$store.commit('transaction/checkout/setOrder', null);
    },
    updated() {
      // if(this.bannerTop) this.jqSlickTopBanner();
      // if(this.bannerBottom) this.jqSlickBotttomBanner();
    },
    async mounted() {
      this.loading = true;
      this.loadingBottom = true;
      this.loadingFrees = true;
      this.loadingCombos = true;
      this.loadingPakets = true;
  
      await Promise.all([
        this.getBanner('1'),
        this.getBanner('2'),
        this.fetchProductGroups(),
        this.fetchFrees(),
        this.fetchCombos(),
      ]);
      
  
      this.initSlickRecomendation();
  
      this.loading = false;
      this.loadingBottom = false;
      this.loadingFrees = false;
      this.loadingCombos = false;
      this.loadingPakets = false;
  
      if(this.$route.hash) {
        this.scrollToHash(this.$route.hash);
      }

      
    },
    watch:{
      bannerTop: function(value){
        this.loading = false;
      },
      bannerBottom: function(value){
        this.loadingBottom =false;
      },
    },
  }
  </script>
  
  <style scoped lang="css">
  @import '../../assets/css/coupon.css';
  @import '../../assets/css/bootstrap.css';
  @import '../../assets/css/timeline.css';
  @import '../../assets/css/reedem.css';
  
  .ol-home-menu{
    padding-top:24px;
  }
  
  @media (max-width: 768px) {
    .ol-home-menu{
      padding-top:0px;
    }
  }

  
.content-location{
    padding: 20px;height: 100%;display: flex;align-items: center;
}

.content-location-detail{
    padding: 0px 20px;
}

.content-location-info{
    margin-top:8px;font-weight: 500;font-size: 16px;color: #424242;
}

.content-location-button-1{
    font-weight: 600;background: #364B7B;border-radius: 4px;border:1px solid #364B7B;color:#FFF;padding:8px 16px;font-size:14px;margin-right:8px;
}
.content-location-button-2{
    font-weight: 600;background: white;border-radius: 4px;border:1px solid #364B7B;color:#364B7B;padding:8px 16px;font-size:14px;margin-right:8px;
}

.cek-produk{
  font-size: 16px;font-weight: 400;margin-top: 16px;
}
@media (max-width: 768px) {
    
    .cek-produk{
      font-size: 12px;font-weight: 400;margin-top: 8px;
    }
    .content-location{
        padding: 8px;height: 100%;display: flex;align-items: center;
    }
    
    .content-location-detail{
        padding: 0px 8px;
    }
    .content-location-info{
        margin-top:8px;font-weight: 500;font-size: 12px;color: #424242;
    }
    .content-location-button-1{
        font-weight: 600;background: white;border-radius: 4px;
        border:1px solid #364B7B;color:#364B7B;padding:8px 16px;
        font-size:12px;margin-right:8px;
        margin-bottom: 8px;
        margin-right:8px;
    }
    .content-location-button-2{
        font-weight: 600;background: white;border-radius: 4px;
        border:1px solid #364B7B;color:#364B7B;padding:8px 16px;
        font-size:12px;margin-right:8px;
        margin-bottom: 8px;
    }

    
}

.wrapper-marketplace {
  display: grid;
  grid-template-columns: repeat(3, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
  text-align: center;
}

.wrapper-event {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
  text-align: center;
}

.wrapper-promo-website{
  display: grid;
  grid-template-columns: repeat(4, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
  text-align: center;
}
.wrapper-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
  text-align: center;
}


.title-moreversary{
  color:white;
  background-image: url('https://asset.morefurniture.id/MORE/asset/background-moreversary.png');
  font-size: 18px;
  background-size: cover;
  border-radius: 8px;
  padding: 8px;
  font-weight: bold;
}

.promo-website{
  color:white;
  background-image: url('https://asset.morefurniture.id/MORE/retail/Desktop_Promo_BG.png');
  font-size: 24px;
  background-size: cover;
  border-radius: 8px;
  padding: 16px;
  font-weight: bold;
  text-align:left;
}

.image-showroom{
  display: block;
}
@media (max-width: 768px) {
  .wrapper-marketplace {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 8px;
    row-gap: 8px;
    text-align: center;
  }

  .wrapper-promo-website{
    display: grid;
    grid-template-columns: repeat(2, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 20px;
    row-gap: 20px;
    text-align: center;
  }
  .wrapper-bottom {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 8px;
    row-gap: 8px;
    text-align: center;
  }

  .wrapper-event {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 8px;
    row-gap: 8px;
    text-align: center;
  }


  .title-moreversary{
    color:white;
    background-image: url('https://asset.morefurniture.id/MORE/asset/background-moreversary.png');
    font-size: 14px;
    background-size: cover;
    border-radius: 8px;
    padding: 8px;
    font-weight: bold;
  }

  .image-showroom{
    display: none;
  }

  .promo-website{
    color:white;
    background-image: url('https://asset.morefurniture.id/MORE/retail/Desktop_Promo_BG.png');
    font-size: 16px;
    background-size: cover;
    border-radius: 8px;
    padding: 8px;
    font-weight: bold;
  }

}

.ol-home-menu-list-item{
  padding: 8px !important;
  border-radius: 8px;
}

.font-moreversary{
  background: linear-gradient(178deg, #364B7B 11.93%, #DC5F56 45.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 800;
}

.ol-promotion-info{
  z-index:200!important;
}


.icon-button {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    color: #6C7C93;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 0.5px solid #ADB5C2;
    border-radius: 8px;
    width: 100%; justify-content: center;

}

.icon-button-1 {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    color: #364B7B;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: #EEF4FC;
    border: 1px solid #364B7B;
    border-radius: 8px;
    width: 100%; 
    justify-content: center;

}

.icon-button-2 {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    color: #FFF;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: #364B7B;
    border: 1px solid #ADB5C2;
    border-radius: 8px;
    width: 100%; 
    justify-content: center;

}


/* Styling for the icon inside the button */
.icon-button .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

</style>